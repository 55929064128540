import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ChatbotLayout, Chatbot } from '@components';


// https://virtualassistantaichatbot-jaylyhjoewg8yfzm2pmffs.streamlit.app/?embedded=True&embed_options=dark_theme
const StyledMainContainer = styled.main`
  counter-reset: section;
  height: 100%; // Set the main container to full viewport height
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  padding: 20px;
  text-align: center;
  color: var(--slate);
  font-size: var(--fz-lg);
`;


const IframeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh; // Change to 90% of viewport height
  margin: 0 auto;
  overflow: hidden;
  flex-grow: 1; // Allow the wrapper to grow and fill available space

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px; // Adjust this value based on the footer's height
    background-color: #0a192f; // Match this with the iframe's background color
    z-index: 1;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; // Set to 100% to fill the wrapper
    border: none;
  }
`;

const IndexPage = ({ location }) => (
  <ChatbotLayout location={location}>
    <StyledMainContainer>
    <Description>
        Welcome to EduBoost! This AI-powered virtual Teaching Assistant is designed to assist you with your questions. EduBoost is currently in the testing phase.
      </Description>
      <IframeWrapper>
        <iframe
          src="https://virtual-ai-chatbot-f55ecb9a53c9.herokuapp.com/?embed=true"
          title="Chatbot iframe"
          tabIndex="-1"
          allowFullScreen
        ></iframe>
      </IframeWrapper>
    </StyledMainContainer>
  </ChatbotLayout>
);

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;